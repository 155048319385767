import { API } from "../../Services";

export const brandList = (query = "") => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BRAND_LIST",
    });
    API.brandList(query)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res)).data.data;
;
       
        setTimeout(() => {
          dispatch({
            type: "RECEIVE_BRAND_LIST",
            payload: data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: "ERROR_BRAND_LIST",
        });
      });
  };
};