import customers from "./customers";
import customer from "./customer";
import projects from "./projects";
import admins from "./admins";
import validateUser from "./validateUser";
import projectsList from "./projectsList";
import project from "./project";
import orders from "./orders";
import products from "./products";
import orderSummary from "./orderSummary";
import customerWallet from "./customerWallet";
import applyCustomerWallet from "./applyCustomerWallet";
import currency from "./currency";
import updateCurrency from "./updateCurrency";
import country from "./country";
import states from "./states";
import media from "./media";
import paymentRequest from "./paymentRequest";
import walletLedger from "./walletLedger";
import clientUpdate from "./clientUpdate";
import login from "./login";
import masterProjects from "./masterProjects";
import products2 from "./products2";
import createCustomOrder from "./createCustomOrder";
import customOrderList from "./customOrderList";
import customOrder from "./customOrder";
import approveCustomOrder from "./approveCustomOrder";
import generateOrderViaCustomOrder from "./generateOrderViaCustomOrder";
import customOrderStatus from "./customOrderStatus";
import createProject from "./createProject";
import requestForm from "./requestForm";
import requestFormList from "./requestFormList";
import rsReminder from "./SendRSReminder";
import rsFormView from "./rsFormView";
import trelloBoards from "./trelloBoards";
import trelloMe from "./trelloMe";
import trelloCards from "./trelloCards";
import trelloCardInfo from "./trelloCardInfo";
import trelloMembersList from "./trelloMembersList";
import trelloList from "./trelloList";
import trelloAttachmentList from "./trelloAttachmentList";
import trelloCommentList from "./trelloCommentList";
import trelloCardUpdate from "./trelloCardUpdate";
import addTrelloComment from "./addComment";
import addTrelloAttachment from "./addTrelloAttachment";
import trelloProject from "./trelloProject";
import trelloCheckList from "./trelloCheckList";
import updateTrelloCheckItem from "./updateTrelloCheckItem";
import addCheckListItem from "./addCheckListItem";
import createCheckList from "./createCheckList";
import trelloCardMove from './trelloCardMove'
import trelloNewList from './trelloNewList'
import trelloCardRemoveMember from './trelloCardRemoveMember'
import trelloAllMembersList from './trelloAllMembersList'
import addMemberToList from './addMemberToList'
import trelloSearchCardList from './trelloSearchCardList'
import projectFolderpath from './projectFolderpath';
import updateProjectFolderpath from './updateProjectFolderpath'
import partnersList from './partnersList';
import sendToPartner from './sendToPartner';
import syncTrello from './syncTrello';
import partnerDetails from './partnerDetails';
import partnerDetailsSingle from './partnerDetailsSingle';
import removeCheckListItem from './removeCheckListItem';
import AssignMemberToChekItem from './assignMemberToChekItem';
import partnerLocations from './partnerLocations';
import units from "./units";
import partnersComissionList from './partnerComissionList'
import partnersComissionWithdrawalList from './partnerComissionWithdrawalList'
import projectsCatlog from './projectsCatlog';
import paymentLinkList from './paymentLinkList';
import transectionList from './transectionList';
import gatewayList from './gatewayList';
import generatePaymentRequest from './generatePaymentRequest';
import currencyList from './currencyList';
import getWalletBalance from './getWalletBalance';
import downlineAdmins from './downlineAdmins';
import partnerleadlist from './partnerleadlist';
import partnerRevokeLead from './partnerRevokeLead';
import getFeedBack from './getFeedBack';
import sendToClient from './sendToClient';
import markDelieverd from './markDelieverd';
import setApprovalStatus from './setApprovalStatus';
import setClientApprovalStatus from './setClientApprovalStatus';
import projectAttachementCategories from './projectAttachementCategories';
import projectAttachementSubCategories from './projectAttachementSubCategories'
import addAttchementCategory from './addAttchementCategory'
import markDeliverable from './markDeliverable'
import SyncRsformFromMaster from "./SyncRsformFromMaster";
import tagMembersToMessage from "./tagMembersToMessage";
import acknowledgeChatMessage from "./acknowledgeChatMessage";
import sendChatMessage from "./sendChatmessage";
import browserFile from "./browserFile";
import browserFileDownload from "./browserFileDownload";
import browserFileActionLogs from "./browserFileActionLogs";
import uploadOfficeData from "./uploadOfficeData";
import refreshBrowserFile from "./refreshBrowserFile";
import updateStorageClass from "./updateStorageClass";
import sitesList from "./sitesList";
import projectDetail from "./projectDetail";
import createSite from "./createSite";
import updateSiteDetail from "./updateSiteDetail";
import interiorDesignList from "./interiorDesignList";
import interiorDesignCategoriesList from "./interiorDesignCategoriesList";
import interiorDesignSubCategoriesList from "./interiorDesignSubCategoriesList";
import updateInteriorDetail from "./updateInteriorDetail";
import brandList from "./brandList";
import utecProjectList from "./utecProjectList";
import updateUtecDetail from "./updateUtecDetail";

const API = {
  customers,
  customer,
  projects,
  admins,
  validateUser,
  projectsList,
  project,
  orders,
  products,
  orderSummary,
  customerWallet,
  applyCustomerWallet,
  currency,
  updateCurrency,
  country,
  states,
  media,
  paymentRequest,
  walletLedger,
  clientUpdate,
  login,
  masterProjects,
  products2,
  createCustomOrder,
  customOrderList,
  customOrder,
  approveCustomOrder,
  generateOrderViaCustomOrder,
  customOrderStatus,
  createProject,
  requestForm,
  requestFormList,
  rsReminder,
  rsFormView,
  trelloBoards,
  trelloMe,
  trelloCards,
  trelloCardInfo,
  trelloMembersList,
  trelloList,
  trelloAttachmentList,
  trelloCommentList,
  trelloCardUpdate,
  addTrelloComment,
  addTrelloAttachment,
  trelloProject,
  trelloCheckList,
  updateTrelloCheckItem,
  addCheckListItem,
  createCheckList,
  trelloCardMove,
  trelloNewList,
  trelloCardRemoveMember,
  trelloAllMembersList,
  addMemberToList,
  trelloSearchCardList,
  projectFolderpath,
  updateProjectFolderpath,
  partnersList,
  sendToPartner,
  syncTrello,
  partnerDetails,
  partnerDetailsSingle,
  removeCheckListItem,
  AssignMemberToChekItem,
  partnerLocations,
  units,
  partnersComissionList,
  partnersComissionWithdrawalList,
  projectsCatlog,
  paymentLinkList,
  transectionList,
  gatewayList,
  generatePaymentRequest,
  currencyList,
  getWalletBalance,
  downlineAdmins,
  partnerleadlist,
  partnerRevokeLead,
  getFeedBack,
  sendToClient,
  markDelieverd,
  setApprovalStatus,
  setClientApprovalStatus,
  projectAttachementCategories,
  projectAttachementSubCategories,
  addAttchementCategory,
  markDeliverable,
  SyncRsformFromMaster,
  tagMembersToMessage,
  acknowledgeChatMessage,
  sendChatMessage,
  browserFile,
  browserFileDownload,
  browserFileActionLogs,
  uploadOfficeData,
  refreshBrowserFile,
  updateStorageClass,
  sitesList,
  projectDetail,
  createSite,
  updateSiteDetail,
  interiorDesignList,
  interiorDesignCategoriesList,
  interiorDesignSubCategoriesList,
  updateInteriorDetail,
  brandList,
  utecProjectList,
  updateUtecDetail,
};

export { API };
